import { isCssVar } from './utils/css-var.es.js';
import { getStyleName } from './utils/get-style-name.es.js';
import { transformDefinitions } from './utils/transforms.es.js';
const style = {
  get: (element, name) => {
    name = getStyleName(name);
    let value = isCssVar(name) ? element.style.getPropertyValue(name) : getComputedStyle(element)[name];
    // TODO Decide if value can be 0
    if (!value && value !== 0) {
      const definition = transformDefinitions.get(name);
      if (definition) value = definition.initialValue;
    }
    return value;
  },
  set: (element, name, value) => {
    name = getStyleName(name);
    if (isCssVar(name)) {
      element.style.setProperty(name, value);
    } else {
      element.style[name] = value;
    }
  }
};
export { style };