import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { CustomerSelectionService } from './customer-selection.service';
import { Customer } from './customer.model';

@Injectable()
export class CustomerInterceptor implements HttpInterceptor {
  constructor(private customer: CustomerSelectionService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (req.url.startsWith('/api')) {
      return this.customer.getCustomer().pipe(
        switchMap((customer: Customer) => {
          req = req.clone({
            setHeaders: { 'X-CustomerID': customer?.customerID != null ? `${customer.customerID}` : 'not a customer' },
          });
          return next.handle(req);
        }),
      );
    }
    return next.handle(req);
  }
}
