import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { provideNgxMask } from 'ngx-mask';

import { AUTH_TOKEN, AuthModule } from '@logic-suite/shared/auth';
import { HTTP_STATUS_REDIRECT } from '@logic-suite/shared/auth/status-redirect.token';
import { CacheModule } from '@logic-suite/shared/cache';
import { ErrorHandlerModule } from '@logic-suite/shared/error';
import { I18nRootModule, START_OF_WEEK, createTranslateLoader } from '@logic-suite/shared/i18n';
import { DEFAULT_TABLE_ROW_SIZE } from '@logic-suite/shared/table';
import { assignEnv, getEnv } from '@logic-suite/shared/utils';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routing';
import { CustomerInterceptor } from './views/customer/customer.interceptor';
import { ErrorInterceptor } from './views/error-pages/error.interceptor';
import { NotAuthorizedComponent } from './views/error-pages/not-authorized/not-authorized.component';

assignEnv(environment);

@Injectable()
export class CustomPaginator extends MatPaginatorIntl {
  getRangeLabel = function (page: number, pageSize: number, length: number) {
    return `${page + 1} / ${Math.ceil(length / pageSize)}`;
  };
}

@NgModule({
  declarations: [AppComponent, NotAuthorizedComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production, // getEnv('production'),
      // Register the ServiceWorker immediately.
      registrationStrategy: 'registerImmediately',
      scope: '/',
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => createTranslateLoader(http, 'flx-admin'),
        deps: [HttpClient],
      },
    }),
    AuthModule,
    I18nRootModule,
    CacheModule,
    ErrorHandlerModule,
    MatIconModule,
    MatButtonModule,
    RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledBlocking' }),
  ],
  providers: [
    { provide: AUTH_TOKEN, useValue: getEnv('auth') },
    { provide: START_OF_WEEK, useValue: 1 },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CustomerInterceptor, multi: true },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'always' } },
    { provide: DEFAULT_TABLE_ROW_SIZE, useValue: 36 },
    { provide: MatPaginatorIntl, useClass: CustomPaginator },
    { provide: HTTP_STATUS_REDIRECT, useValue: [{ statusCode: 403, redirectTo: ['/customer'] }] },
    provideNgxMask(),
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
