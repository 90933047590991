var warning = function () {};
var invariant = function () {};
if (process.env.NODE_ENV !== 'production') {
  warning = function (check, message) {
    if (!check && typeof console !== 'undefined') {
      console.warn(message);
    }
  };
  invariant = function (check, message) {
    if (!check) {
      throw new Error(message);
    }
  };
}
export { invariant, warning };