import { transformDefinitions } from './transforms.es.js';
const isCssVar = name => name.startsWith("--");
const registeredProperties = new Set();
function registerCssVariable(name) {
  if (registeredProperties.has(name)) return;
  registeredProperties.add(name);
  try {
    const {
      syntax,
      initialValue
    } = transformDefinitions.has(name) ? transformDefinitions.get(name) : {};
    CSS.registerProperty({
      name,
      inherits: false,
      syntax,
      initialValue
    });
  } catch (e) {}
}
export { isCssVar, registerCssVariable, registeredProperties };