import { mix } from './mix.es.js';
import { noopReturn } from './noop.es.js';
import { fillOffset, defaultOffset } from './offset.es.js';
import { progress } from './progress.es.js';
import { getEasingForSegment } from './easing.es.js';
import { clamp } from './clamp.es.js';
function interpolate(output, input = defaultOffset(output.length), easing = noopReturn) {
  const length = output.length;
  /**
   * If the input length is lower than the output we
   * fill the input to match. This currently assumes the input
   * is an animation progress value so is a good candidate for
   * moving outside the function.
   */
  const remainder = length - input.length;
  remainder > 0 && fillOffset(input, remainder);
  return t => {
    let i = 0;
    for (; i < length - 2; i++) {
      if (t < input[i + 1]) break;
    }
    let progressInRange = clamp(0, 1, progress(input[i], input[i + 1], t));
    const segmentEasing = getEasingForSegment(easing, i);
    progressInRange = segmentEasing(progressInRange);
    return mix(output[i], output[i + 1], progressInRange);
  };
}
export { interpolate };