<header>
  <div class="page">
    <figure>
      <img src="/assets/nav_logo.svg" />
    </figure>
    <a
      [routerLink]="['customer']"
      [queryParams]="{ returnTo: returnPath }"
      class="customer"
      [title]="'Change selected company' | translate"
    >
      {{ selectedCustomer?.name }}
    </a>
    <nav>
      <ul>
        <li
          *ngFor="let route of routes"
          [routerLinkActive]="'current'"
          class="list-item"
          #item
          [attr.data-item]="route.path"
        >
          <a [routerLink]="[route.path]">
            <mat-icon>{{ route.icon }}</mat-icon>
            <span>{{ route.label | translate }}</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</header>
<main>
  <router-outlet></router-outlet>
</main>

<footer class="page"></footer>
