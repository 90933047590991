import { isNumber, isFunction } from '@motionone/utils';
import { getEasingFunction } from '@motionone/animation';
function stagger(duration = 0.1, {
  start = 0,
  from = 0,
  easing
} = {}) {
  return (i, total) => {
    const fromIndex = isNumber(from) ? from : getFromIndex(from, total);
    const distance = Math.abs(fromIndex - i);
    let delay = duration * distance;
    if (easing) {
      const maxDelay = total * duration;
      const easingFunction = getEasingFunction(easing);
      delay = easingFunction(delay / maxDelay) * maxDelay;
    }
    return start + delay;
  };
}
function getFromIndex(from, total) {
  if (from === "first") {
    return 0;
  } else {
    const lastIndex = total - 1;
    return from === "last" ? lastIndex : lastIndex / 2;
  }
}
function resolveOption(option, i, total) {
  return isFunction(option) ? option(i, total) : option;
}
export { getFromIndex, resolveOption, stagger };